<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="elevation-1">
                    <div class="dash-container">
                        <div class="dash-company-info">
                            <div class="intro">
                                <h1>accounting</h1>
                                <p class="text--lighten-5 company-slogan" style="font-size: 20px;">
                                    {{$auth.company('slogan').length ?
                                    $auth.company('slogan') : 'The ultimate destination for quality education'}}...
                                </p>
                                <!--<v-btn @click="account">Send Message Dev</v-btn>-->

                                <!--<iframe @load="onLoadHandler()" style="margin-left: 300px;" id="recieve"-->
                                <!--src="http://localhost:4000" width="200" height="100">-->
                                <!--<p>Your browser does not support iframes.</p>-->
                                <!--</iframe>-->
                                <button id="send" @click="redirect">Go to Account Page</button>
                            </div>
                        </div>
                    </div>
                </v-card>

            </v-flex>
        </v-layout>
    </v-container>

</template>
<script>

    import cookie from 'js-cookie'
    export default {
        data: () => ({
            push: null
        }),
        computed: {},
        mounted() {


        },
        methods: {
            redirect() {
                let receiver = document.getElementById('recieve');
                let push = (receiver.contentWindow);
                let data = [];
                data['local_storage'] = {
                    _currentFiscalYear: localStorage.getItem('_currentFiscalYear'),
                    _permissions: localStorage.getItem('_permissions'),
                    _token: localStorage.getItem('_token'),
                    // batch: localStorage.getItem('batch'),
                    _role: localStorage.getItem('_role'),
                    _user: localStorage.getItem('_user'),
                };
                data['cookie'] = {
                    _cl: cookie.get('_cl'),
                    // _batch: cookie.get('_batch'),
                    _company: cookie.get('_company'),
                };

                let transferedUri = 'http://localhost:4000';

                let uri = window.location.host;
                uri = uri.replace(/^.{2,6}\./, '');
                if (uri === 'lms.vidhyalaya.org') {
                    uri = uri.replace(/lms./, '')
                }

                if (uri === 'localhost:8080' || uri === 'localhost:8081') {
                    transferedUri = 'http://localhost:4000'
                } else {
                    transferedUri = 'https://lekhangkan.' + uri;
                }
                console.log(transferedUri);

                push.postMessage(data, transferedUri);
                window.open(
                    transferedUri,
                    '_blank'
                );
            }
        },

        beforeDestroy() {
        },

    }
</script>
<style lang="scss">
</style>
